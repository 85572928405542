export const occasions = {
  route: () => import('./Occasions.vue'),
  propTypes: {
    title: {
      type: String,
      default: 'Toutes les occasions méritent une belle box cadeau',
    },
    viewMore: {
      type: Object,
      default: {
        text: 'View More',
        link: 'nos-smartbox/sejour/',
      },
    },
    items: {
      type: Array,
      required: true,
    },
  },
  devProps: {
    items: [
      {
        label: "Cadeaux d'anniversaire",
        link: 'cadeau-anniversaire/',
        images: {
          base: 'https://media.smartbox.com/media/war/2022/home/IT_Birthday_Block_236x236.jpg',
        },
      },
      {
        label: 'Cadeaux d’anniversaire de mariage',
        link: 'idee-cadeau-anniversaire-mariage/',
        images: {
          base: 'https://media.smartbox.com/media/war/2022/home/IT_Anniversary_Block_236x236.jpg',
        },
      },
      {
        label: 'Cadeaux de mariage',
        link: 'cadeau-de-mariage/',
        images: {
          base: 'https://media.smartbox.com/media/war/2022/home/IT_HP_Widget_Wedding_236x236.jpg',
        },
      },
      {
        label: 'Cadeaux de félicitations',
        link: 'idees-cadeaux-felicitations/',
        images: {
          base: 'https://media.smartbox.com/media/war/2022/home/IT_Graduation_Block_236x236.jpg',
        },
      },
      {
        label: 'Cadeaux de remerciement',
        link: 'cadeau-remerciement/',
        images: {
          base: 'https://media.smartbox.com/media/war/2022/home/IT_ThankYou_Widget_236x236.jpg',
        },
      },
    ],
  },
};
