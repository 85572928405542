/* eslint-disable no-unused-vars */

// eslint-disable-next-line no-unused-vars
function callback(entries, observer) {
  // Loop through the entries

  const viewportRect = {
    top: 0,
    left: 0,
    bottom: window.innerHeight,
    right: window.innerWidth,
  };

  for (const [index, entry] of entries.entries()) {
    const { target, isIntersecting, intersectionRatio } = entry;
    const { name } = target._wrapper.$refs.inner;
    target._wrapper.$refs.inner.componentControl.visibility = false;
    // console.warn({index: index+1, total: entries.length, name, isIntersecting, intersectionRatio})
    if (isIntersecting || intersectionRatio > 0) {
      // console.warn(`${name} is intersecting, enable visibility of component`, target);
      target._wrapper.$refs.inner.componentControl.visibility = true;
      observer.unobserve(target);
    }

    if (!isIntersecting && intersectionRatio == 0) {
      // console.warn(`${name} is not intersecting, lets disable the visibility`, target);
      target._wrapper.$refs.inner.componentControl.visibility = false;
    }

    // Get the current DOM rect of the entry
    const domRect = target.getBoundingClientRect();

    // Check if the current DOM rect is less than the current viewport rect
    if (
      domRect.top < viewportRect.bottom &&
      domRect.bottom > viewportRect.top &&
      domRect.left < viewportRect.right &&
      domRect.right > viewportRect.left
    ) {
      // console.warn(`${name} is intersecting, enable visibility of component`, target);
      target._wrapper.$refs.inner.componentControl.visibility = true;
      observer.unobserve(target);
    }
  }
}

// Create an IntersectionObserver instance with the callback function and some options
const options = {
  rootMargin: '150px 0px 150px 0px',
};

export const observer = new IntersectionObserver(callback, options);
