export const heroBannerCarousel = {
  route: () => import('./HeroBannerCarousel.vue'),
  propTypes: {
    slides: {
      type: Array,
      required: true,
    },
    h1Title: {
      type: String,
      required: false,
    },
  },
  devProps: {
    slides: [
      {
        images: {
          mobile: 'https://media.smartbox.com/media/war/2022/home/SBX_Xmas22_Widget_ForCouple_303x234.jpg',
          base: 'https://images.unsplash.com/photo-1480714378408-67cf0d13bc1b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8Mnx8fGVufDB8fHx8&w=1000&q=80',
        },
        headerTitle: 'Special Moments Spent Together',
        headerDescription: 'The happiness of giving to your loved ones from home',
        headerAlignment: 'left',
        link: '#slide1Link',
        buttonsAlignment: 'center',
        overlay: 'true',
        buttons: [
          {
            viewSize: ['mobile', 'contained', '', 'full-width'],
            text: 'button1',
            link: 'test/',
            bgColor: 'pink',
            textColor: 'green',
          },
          {
            viewSize: ['mobile', 'contained', 'full-width'],
            text: 'button2',
            link: '#button2click',
            bgColor: 'red',
            textColor: '#FFE598',
          },
          {
            viewSize: ['contained', 'full-width'],
            text: 'button3',
            link: '#button3click',
            bgColor: 'green',
            textColor: '#FFE598',
          },
          {
            viewSize: ['contained', 'full-width'],
            text: 'button4',
            link: '#button4click',
            bgColor: 'blue',
            textColor: '#FFE598',
          },
          {
            viewSize: ['mobile', 'contained', 'full-width'],
            text: 'button5',
            link: '#button5click',
            bgColor: 'yellow',
            textColor: '#FFE598',
          },
        ],
      },
      {
        images: {
          mobile: 'https://media.smartbox.com/media/war/2022/home/SBX_Xmas22_Widget_ForCouple_303x234.jpg',
          base: 'https://images.unsplash.com/photo-1557855226-e63c7f07d9fb?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1000&q=80',
        },
        headerTitle: 'Second slide',
        headerDescription: 'Test slide with no button',
        link: '#slide2link',
        overlay: 'false',
      },
    ],
    h1Title: 'true',
  },
};
