export const productList = {
  route: () => import('./ProductList.vue'),
  propTypes: {
    pimIds: {
      type: String,
      default: '',
    },
    digitalFilters: {
      type: Object,
      default: { overnight_stay: 'yes' },
      required: true,
    },
    numberOfProductsToShow: {
      type: String,
      default: '4',
    },
    seeMore: {
      type: Object,
      link: {
        type: String,
        default: '',
      },
      text: {
        type: String,
        default: '',
      },
      default: {
        link: '',
        text: '',
      },
    },
    title: {
      type: String,
      default: '',
    },
    subTitle: {
      type: String,
      default: '',
    },
  },
  devProps: {
    digitalFilters: {
      overnight_stay: 'yes',
    },
  },
};
