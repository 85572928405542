export const merchSubCategoryV4 = {
  route: () => import('./MerchSubCategoryV4.vue'),
  propTypes: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      required: true,
    },
  },
  devProps: {
    items: [
      {
        label: 'Cadeaux couple',
        link: 'coffret-cadeau-couple/',
        images: {
          mobile: 'https://media.smartbox.com/media/war/2022/home/SBX_Xmas22_Widget_ForCouple_303x234.jpg',
          base: 'https://media.smartbox.com/media/war/2022/home/SBX_Xmas22_Widget_ForCouple_303x234.jpg',
        },
      },
      {
        label: 'Cadeaux femme',
        link: 'coffret-cadeau-pour-femme/',
        images: {
          mobile: 'https://media.smartbox.com/media/war/2022/home/SBX_Winter_ForHer_Widget_303x234.jpg',
          base: 'https://media.smartbox.com/media/war/2022/home/SBX_Winter_ForHer_Widget_303x234.jpg',
        },
      },
      {
        label: 'Cadeaux homme',
        link: 'coffret-cadeau-homme/',
        images: {
          mobile: 'https://media.smartbox.com/media/war/2022/home/SBX_Xmas22_Widget_ForHim_303x234.jpg',
          base: 'https://media.smartbox.com/media/war/2022/home/SBX_Xmas22_Widget_ForHim_303x234.jpg',
        },
      },
      {
        label: 'Activités en famille',
        link: 'activites-en-famille/',
        images: {
          mobile: 'https://media.smartbox.com/media/war/2022/home/SBX_Winter_Family_Widget_303x234.jpg',
          base: 'https://media.smartbox.com/media/war/2022/home/SBX_Winter_Family_Widget_303x234.jpg',
        },
      },
    ],
  },
};
