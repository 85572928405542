const curDate = new Date();

export const countdownTimer = {
  route: () => import('./CountdownTimer.vue'),
  propTypes: {
    year: {
      type: String,
      required: true,
    },
    month: {
      type: String,
      required: true,
    },
    day: {
      type: String,
      required: true,
    },
    hour: {
      type: String,
      required: true,
    },
    minute: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
    bgColor: {
      type: String,
      required: true,
    },
    format: {
      type: String,
      default: 'block',
      validator: function (value) {
        return ['block', 'inline'].includes(value);
      },
    },
  },
  devProps: {
    year: curDate.getFullYear().toString(),
    month: (curDate.getMonth() + 2).toString(),
    day: '0',
    hour: '0',
    minute: '0',
    seconds: '0',
    color: '#000000',
    bgColor: '#ffffff',
    format: 'block',
  },
};
