export const merchSubCategoryV1 = {
  route: () => import('./MerchSubCategoryV1.vue'),
  propTypes: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      required: true,
    },
  },
  devProps: {
    items: [
      {
        label: 'Cadeaux couple',
        link: 'nos-smartbox/sejour/',
        images: {
          mobile: 'https://media.smartbox.com/media/war/2021/xmas/SBX_Winter_ForHer_Widget_303x234.jpg',
          base: 'https://media.smartbox.com/media/war/2021/xmas/SBX_Xmas21_ForCouple_Widget_303x234.jpg',
        },
      },
      {
        label: 'Cadeaux homme',
        link: 'nos-smartbox/gastro/',
        images: {
          mobile: 'https://media.smartbox.com/media/war/2021/xmas/SBX_Winter_ForHer_Widget_303x234.jpg',
          base: 'https://media.smartbox.com/media/war/2021/xmas/SBX_Xmas21_ForHim_Widget_303x234.jpg',
        },
      },
      {
        label: 'Cadeaux femme',
        link: 'nos-smartbox/pilotage/',
        images: {
          mobile: 'https://media.smartbox.com/media/es/test/SBX_Winter_Birthday_Widget_236x236.jpg',
          base: 'https://media.smartbox.com/media/war/2021/xmas/SBX_Xmas21_ForHer_Widget_303x234.jpg',
        },
      },
      {
        label: 'Cadeaux papa',
        link: 'nos-smartbox/sejour/',
        images: {
          mobile: 'https://media.smartbox.com/media/es/test/SBX_Winter_Anniversary_Widget_236x236.jpg',
          base: 'https://media.smartbox.com/media/war/2021/xmas/SBX_Xmas21_ForDad_Widget_303x234.jpg',
        },
      },
      {
        label: 'Cadeaux maman',
        link: 'nos-smartbox/sejour/',
        images: {
          mobile: 'https://media.smartbox.com/media/es/test/SBX_Winter_Graduation_Widget_236x236.jpg',
          base: 'https://media.smartbox.com/media/war/2021/xmas/SBX_Xmas21_ForMom_Widget_303x234.jpg',
        },
      },
    ],
  },
};
