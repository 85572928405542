import Vue from 'vue';
import SbxPcc from './pages/SbxPCC_Wrapper.vue';
import Index from './pages/PCCTool/Index.vue';
import wrap from '@vue/web-component-wrapper';
import { affixScriptToHead } from './SharedFe/utils/miscUtils';

if (process.env.NODE_ENV !== 'development') {
  /* eslint-disable */
  let wrappedElement;
  wrappedElement = wrap(Vue, SbxPcc);
  window.customElements.define('sbx-pcc', wrappedElement);
} else {
  new Vue({
    render: h => h(Index),
  }).$mount('#app');

  //Dev only Loading external web components SC and PCC library for SEO generation.
  fetch('https://precart.smartbox.com/register_js.phtml')
    .then(response => response.text())
    .then(text => {
      const urls = text.split('"').filter(ele => ele.match(/precart/g));
      urls.forEach(url => {
        affixScriptToHead(url, () => {
          console.warn(`${url} has been correctly loaded`);
        });
      });
    });
  fetch('https://search-components.smartbox.com/register_js.phtml')
    .then(response => response.text())
    .then(text => {
      const urls = text.split('"').filter(ele => ele.match(/seacat/g));
      urls.forEach(url => {
        affixScriptToHead(url, () => {
          console.warn(`${url} has been correctly loaded`);
        });
      });
    });
}
