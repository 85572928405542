<template>
  <div :style="brandingRoot.styles" data-testid="sbx-pcc_wrapper-root">
    <div v-if="componentControl.errorMessage !== null" class="error" data-testid="sbx-error-block">
      {{ componentControl.errorMessage }}
    </div>
    <component v-if="componentControl.visibility == true" :is="optionComponent" ref="pcc" v-bind="computedProps" />
  </div>
</template>

<script>
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import componentExports from '@/components/componentExports';
import atomExports from '@/atoms/atomExports';
import { addFontsStyleTag, removeFontsStyleTag } from '@/SharedFe/utils/fontUtils';
import { prodHosts } from '@/SharedFe/utils/urlUtils';
import { screenSizesMixin } from '@/SharedFe/utils/mixins/screenSizesMixin';
import { brandingImporter } from '@/SharedFe/utils';
import { observer } from '@/SharedFe/utils/observer';
import VueSanitize from 'vue-sanitize';

const sanitizeOptions = {
  allowedTags: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'i', 'b', 'strong', 'div', 'p', 'span', 'br', 'sup', 'a', 'ul', 'ol', 'li'],
  allowedAttributes: {
    '*': ['style', 'href', 'class'],
  },
};

Vue.use(VueI18n);
Vue.use(VueSanitize, sanitizeOptions);

const combinedExports = {
  ...componentExports,
  ...atomExports,
};

const i18n = new VueI18n({
  messages: {
    en: require('../SharedFe/locale/en.json'),
    fr: require('../SharedFe/locale/fr.json'),
    es: require('../SharedFe/locale/es.json'),
    it: require('../SharedFe/locale/it.json'),
    de: require('../SharedFe/locale/de.json'),
    nl: require('../SharedFe/locale/nl.json'),
    da: require('../SharedFe/locale/da.json'),
    sv: require('../SharedFe/locale/sv.json'),
  },
  silentTranslationWarn: true,
});
const plugin = {
  install() {
    Vue.prototype.$_fb = (customised = '', fallback) => (customised !== '' ? customised : fallback);
  },
};
Vue.use(plugin);

export default Vue.extend({
  name: 'PCCWrapper',
  i18n,
  mixins: [screenSizesMixin],
  data() {
    return {
      combinedExports,
      componentControl: {
        visibility: false,
        errorMessage: null,
      },
      brandingRoot: '',
    };
  },
  props: {
    options: {
      type: Object,
      default: null,
    },
    name: {
      type: String,
      default: '',
    },
    lang: {
      type: String,
      default: '',
    },
  },
  watch: {
    computedProps() {
      this.optionsImportValidator();
    },
    lang() {
      this.setLangCode(this.lang);
    },
  },
  computed: {
    computedProps() {
      const opts = typeof this.options === 'string' ? this.validateStringProps() : { ...this.options };
      return {
        ...opts,
        screenSize: this.screenSize,
        screenPx: this.screenPx,
        getErrorMessage: message => {
          if (!this.componentControl.errorMessage) {
            this.validatorErrorHandler(message);
          }
        },
      };
    },
    optionComponent() {
      const component = this.name in combinedExports;
      if (component) {
        const mod = combinedExports[this.name].route;
        return mod;
      }
      return false;
    },
    isProductionServer() {
      return prodHosts.includes(window.location.host);
    },
  },
  created() {
    addFontsStyleTag();
    this.brandingRoot = brandingImporter();
  },
  beforeDestroy() {
    removeFontsStyleTag();
  },
  async mounted() {
    if (this.computedProps) {
      this.optionsImportValidator();
    }
    const tmpLang = this.lang ? this.lang : this.brandingRoot.contextHeaders.language;
    this.setLangCode(tmpLang);
    // Select all elements with data-testid="sbx-pcc_wrapper-root" and observe them
    const elements = document.querySelectorAll("sbx-pcc:not([name='newsletterPopup']):not([name='header']), sbx-sc");
    for (const element of elements) {
      observer.observe(element);
    }
  },
  methods: {
    validatorErrorHandler(message) {
      const hiddenWarning = `the component will be hidden until required properties are passed.`;
      if (this.isProductionServer) {
        // New Relic call to go here; pass message & hide component
        // console.error(`${message} ${hiddenWarning}`);

        this.componentControl.visibility = false;
      } else {
        const title = 'NON-PRODUCTION WARNING - ';
        const warning = `In a production environment this message will be removed, ${hiddenWarning}`;
        this.componentControl.errorMessage = title + message + warning;
        this.componentControl.visibility = true;
      }
    },
    optionsImportValidator() {
      this.componentControl.errorMessage = null;
      const errorProps = [];
      const { propTypes } = combinedExports[this.name];
      for (const [propKey, propKeyInfo] of Object.entries(propTypes)) {
        // First, any undefined props and check if required
        const propTypeCheckUndefined = typeof this.computedProps[propKey] == 'undefined';
        if (propTypeCheckUndefined && propKeyInfo.hasOwnProperty('required')) {
          errorProps.push(propKey);
        }
        // Second, check any defined props and if they do not match the type
        if (!propTypeCheckUndefined && typeof propKeyInfo.type() !== typeof this.computedProps[propKey]) {
          errorProps.push(propKey);
        }

        if (propTypes[propKey].validate) {
          const err = propTypes[propKey].validate(this.computedProps[propKey]);

          if (err && err.length) {
            errorProps.push(err.join(','));
          }
        }
      }
      if (errorProps.length) {
        const joined = errorProps.join(', ');
        this.validatorErrorHandler(`[${joined}] - invalid prop(s). Please check your input options. `);
      } else {
        this.componentControl.visibility = true;
      }
    },
    setLangCode(lang) {
      i18n.locale = lang;
    },
    validateStringProps() {
      try {
        return JSON.parse(this.options);
      } catch (e) {
        console.error(`parseError for ${this.name}, ${this.brandingRoot.contextKey}. Please check options`);
      }
    },
  },
});
</script>

<style lang="scss" scoped>
@import '@/SharedFe/scss/variables.scss';

* {
  font-family: $brand-font-regular;
}

:host {
  all: initial;
}

.error {
  max-width: 800px;
  margin: 10px auto;
  border: red 1px dashed;
  padding: 5px 0px;
}

.max-view-wide {
  max-width: $wide;
}

.max-view-contained {
  max-width: $contained;
}

.max-view-fullWidth {
  max-width: $fullWidth;
}

.max-view-mobile {
  max-width: $mobile;
}
</style>
