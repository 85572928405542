export const highlightedProduct = {
  route: () => import('./HighlightedProduct.vue'),
  propTypes: {
    pimId: {
      type: String,
      required: true,
    },
  },
  devProps: {
    pimId: '1408948',
  },
};
