export const multiLinkBlocks = {
  route: () => import('./MutliLinkBlocks.vue'),
  propTypes: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      required: true,
    },
  },
  devProps: {
    items: [
      {
        image: 'https://media.smartbox.com/media/war/2021/stay/FR_OriginalGifts_Block_DaysOut_768x650.jpg',
        blockTitle: 'Séjours avec une activité incluse',
        backgroundColor: '#822B86',
        links: [
          {
            label: 'Avec dégustation de vins',
            link: 'nos-smartbox/sejour/vins',
          },
          {
            label: 'Avec accès au spa',
            link: 'nos-smartbox/sejour/spa',
          },
          {
            label: 'Avec soin bien-être',
            link: 'nos-smartbox/sejour/bien-etre',
          },
          {
            label: 'Mille et une nuits',
            link: 'nos-smartbox/sejour/milletunenuits',
          },
        ],
      },
      {
        image: 'https://media.smartbox.com/media/war/2021/stay/FR_GiftIdeas_Block_RegionalExperiences_630x390.jpg',
        blockTitle: 'Séjours par région',
        backgroundColor: '#00C4B3',
        links: [
          {
            label: 'PACA',
            link: 'nos-smartbox/provence-alpes-cote-azur-corse',
          },
          {
            label: 'Bretagne',
            link: 'nos-smartbox/bretagne',
          },
          {
            label: 'Occitanie',
            link: 'nos-smartbox/0ccitanie',
          },
          {
            label: 'Auvergne Rhône-Alpes',
            link: 'nos-smartbox/auvergne-rhone-alpes',
          },
        ],
      },
      {
        image: 'https://media.smartbox.com/media/war/2021/stay/FR_Stays_Block_Europe_768x650.jpg',
        blockTitle: "Séjours à l'étranger",
        backgroundColor: '#F7A600',
        links: [
          {
            label: 'Afrique et Moyen-Orient',
            link: 'nos-smartbox/experiences-hors-europe',
          },
          {
            label: 'Europe du Sud',
            link: 'nos-smartbox/sejour-en-europe',
          },
          {
            label: 'Europe du Nord',
            link: 'nos-smartbox/sejour-en-europe/europe-du-nord',
          },
          {
            label: "Europe de l'Est",
            link: 'nos-smartbox/sejour-en-europe/europe-de-lest',
          },
        ],
      },
    ],
  },
};
