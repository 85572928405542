export const landingFAQ = {
  route: () => import('./LandingFAQ.vue'),
  propTypes: {
    title: {
      type: String,
      default: 'Stay box: frequently asked questions by our customers',
    },
    items: {
      type: Array,
      required: true,
    },
  },
  devProps: {
    items: [
      {
        title: 'What is a travel box?',
        subtitle:
          "In a travel box, you will find a multitude of partners to offer a loved one or yourself a stay adapted to everyone's desires. Some stay boxes are designed according to a theme to enjoy a romantic weekend or an unusual night for example, while other travel boxes bring together partners offering all types of accommodation. Here is a nice selection of travel gift boxes so that you can easily find the one you need!",
        list: [],
      },
      {
        title: 'How to use a stay box?',
        subtitle: 'To use your Smartbox stay box, all you need to do is:',
        list: [
          'go to <a href="https://www.smartbox.com/fr/">www.smartbox.com/fr/</a>',
          'register your gift voucher',
          'find the partner with whom you wish to stay',
          'book the stay of your choice',
        ],
      },
      {
        title: 'What are the best destinations for a romantic stay in France?',
        subtitle: 'To enjoy the most beautiful destinations for a romantic getaway in France, here is what Cupidon recommends:',
        list: [
          'A <a href="https://www.smartbox.com/fr/paris/">stay in Paris</a> , the capital of romanticism',
          'A <a href="https://www.smartbox.com/fr/provence-alpes-cote-azur-corse/">trip to the seaside on the French Riviera</a> in a room with a breathtaking view',
          'A <a href="https://www.smartbox.com/fr/nos-smartbox/sejour/chateaux/">weekend in a castle</a> to live a fairy tale',
          'An <a href="https://www.smartbox.com/fr/sejour-insolite/">unusual getaway</a> to discover France differently',
        ],
      },
    ],
  },
};
