export const seoLinkRepository = {
  route: () => import('./SeoLinkRepository.vue'),
  propTypes: {
    title: {
      type: String,
      default: "Coffret Cadeau : trouvez la box cadeau qu'il vous faut",
    },
    subtitle: {
      type: String,
      default: 'Des idées cadeaux pour tous vos proches',
    },
    items: {
      type: Array,
      required: true,
    },
  },
  devProps: {
    items: [
      {
        title: 'Coffret cadeau Femme',
        subtitle: 'Toutes nos box cadeau pour combler de joie les femmes de votre entourage.',
        links: [
          {
            label: 'Idées cadeau maman',
            link: 'idee-cadeau-maman/',
          },
          {
            label: 'Cadeaux anniversaire femme',
            link: 'idee-cadeau-anniversaire-femme/',
          },
          {
            label: 'Cadeaux noël maman',
            link: 'idees-cadeaux-originales-noel/pour-maman/',
          },
          {
            label: 'Cadeau grand-mère',
            link: 'idee-cadeau-grand-mere/',
          },
          {
            label: 'Coffret Cadeau femme',
            link: 'coffret-cadeau-pour-femme/',
          },
          {
            label: 'Anniversaire de mariage',
            link: 'idee-cadeau-anniversaire-mariage/',
          },
          {
            label: 'Cadeaux de naissance',
            link: 'cadeau-naissance/',
          },
        ],
      },
      {
        title: 'Coffret cadeau Homme',
        subtitle: "Plein d'idées originales de cadeaux pour homme pour leur faire plaisir à coup sûr.",
        links: [
          {
            label: 'Idées cadeau maman',
            link: 'idee-cadeau-maman/',
          },
          {
            label: 'Cadeaux anniversaire femme',
            link: 'idee-cadeau-anniversaire-femme/',
          },
          {
            label: 'Cadeaux noël maman',
            link: 'idees-cadeaux-originales-noel/pour-maman/',
          },
          {
            label: 'Cadeau grand-mère',
            link: 'idee-cadeau-grand-mere/',
          },
          {
            label: 'Coffret Cadeau femme',
            link: 'coffret-cadeau-pour-femme/',
          },
          {
            label: 'Anniversaire de mariage',
            link: 'idee-cadeau-anniversaire-mariage/',
          },
          {
            label: 'Cadeaux de naissance',
            link: 'cadeau-naissance/',
          },
        ],
      },
      {
        title: 'Coffrets Sport & Aventure',
        subtitle: 'Des box découvertes pour les aventuriers à la recherche de nouvelles sensations fortes !',
        links: [
          {
            label: 'Idées cadeau maman',
            link: 'idee-cadeau-maman/',
          },
          {
            label: 'Cadeaux anniversaire femme',
            link: 'idee-cadeau-anniversaire-femme/',
          },
          {
            label: 'Cadeaux noël maman',
            link: 'idees-cadeaux-originales-noel/pour-maman/',
          },
          {
            label: 'Cadeau grand-mère',
            link: 'idee-cadeau-grand-mere/',
          },
          {
            label: 'Coffret Cadeau femme',
            link: 'coffret-cadeau-pour-femme/',
          },
          {
            label: 'Anniversaire de mariage',
            link: 'idee-cadeau-anniversaire-mariage/',
          },
          {
            label: 'Cadeaux de naissance',
            link: 'cadeau-naissance/',
          },
        ],
      },
      {
        title: 'Tendances 2023',
        subtitle: 'Découvrez les tendances du moment pour offrir une box découverte qui fera sensation !',
        links: [
          {
            label: 'Idées cadeau maman',
            link: 'idee-cadeau-maman/',
          },
          {
            label: 'Cadeaux anniversaire femme',
            link: 'idee-cadeau-anniversaire-femme/',
          },
          {
            label: 'Cadeaux noël maman',
            link: 'idees-cadeaux-originales-noel/pour-maman/',
          },
          {
            label: 'Cadeau grand-mère',
            link: 'idee-cadeau-grand-mere/',
          },
          {
            label: 'Coffret Cadeau femme',
            link: 'coffret-cadeau-pour-femme/',
          },
          {
            label: 'Anniversaire de mariage',
            link: 'idee-cadeau-anniversaire-mariage/',
          },
          {
            label: 'Cadeaux de naissance',
            link: 'cadeau-naissance/',
          },
        ],
      },
      {
        title: 'Box Cadeau Homme',
        subtitle: 'Smartbox aux cotés des hommes pour que tous les anniversaires soient uniques.',
        links: [
          {
            label: 'Idées cadeau maman',
            link: 'idee-cadeau-maman/',
          },
          {
            label: 'Cadeaux anniversaire femme',
            link: 'idee-cadeau-anniversaire-femme/',
          },
          {
            label: 'Cadeaux noël maman',
            link: 'idees-cadeaux-originales-noel/pour-maman/',
          },
          {
            label: 'Cadeau grand-mère',
            link: 'idee-cadeau-grand-mere/',
          },
          {
            label: 'Coffret Cadeau femme',
            link: 'coffret-cadeau-pour-femme/',
          },
          {
            label: 'Anniversaire de mariage',
            link: 'idee-cadeau-anniversaire-mariage/',
          },
          {
            label: 'Cadeaux de naissance',
            link: 'cadeau-naissance/',
          },
        ],
      },
      {
        title: 'Box Cadeau Femme',
        subtitle: 'De 18 à 80 ans, Smartbox reste à vos côtés pour sans cesse surprendre les femmes !',
        links: [
          {
            label: 'Idées cadeau maman',
            link: 'idee-cadeau-maman/',
          },
          {
            label: 'Cadeaux anniversaire femme',
            link: 'idee-cadeau-anniversaire-femme/',
          },
          {
            label: 'Cadeaux noël maman',
            link: 'idees-cadeaux-originales-noel/pour-maman/',
          },
          {
            label: 'Cadeau grand-mère',
            link: 'idee-cadeau-grand-mere/',
          },
          {
            label: 'Coffret Cadeau femme',
            link: 'coffret-cadeau-pour-femme/',
          },
          {
            label: 'Anniversaire de mariage',
            link: 'idee-cadeau-anniversaire-mariage/',
          },
          {
            label: 'Cadeaux de naissance',
            link: 'cadeau-naissance/',
          },
        ],
      },
      {
        title: 'Cadeau de Noël',
        subtitle: "En manque d'idées ? Trouvez facilement un coffret de Noël pour ceux que vous aimez !",
        links: [
          {
            label: 'Idées cadeau maman',
            link: 'idee-cadeau-maman/',
          },
          {
            label: 'Cadeaux anniversaire femme',
            link: 'idee-cadeau-anniversaire-femme/',
          },
          {
            label: 'Cadeaux noël maman',
            link: 'idees-cadeaux-originales-noel/pour-maman/',
          },
          {
            label: 'Cadeau grand-mère',
            link: 'idee-cadeau-grand-mere/',
          },
          {
            label: 'Coffret Cadeau femme',
            link: 'coffret-cadeau-pour-femme/',
          },
          {
            label: 'Anniversaire de mariage',
            link: 'idee-cadeau-anniversaire-mariage/',
          },
          {
            label: 'Cadeaux de naissance',
            link: 'cadeau-naissance/',
          },
        ],
      },
      {
        title: 'D’autres box à découvrir',
        subtitle: "A la recherche d'une box originale à offrir ? Laissez-nous vous inspirer avec nos coffrets.",
        links: [
          {
            label: 'Idées cadeau maman',
            link: 'idee-cadeau-maman/',
          },
          {
            label: 'Cadeaux anniversaire femme',
            link: 'idee-cadeau-anniversaire-femme/',
          },
          {
            label: 'Cadeaux noël maman',
            link: 'idees-cadeaux-originales-noel/pour-maman/',
          },
          {
            label: 'Cadeau grand-mère',
            link: 'idee-cadeau-grand-mere/',
          },
          {
            label: 'Coffret Cadeau femme',
            link: 'coffret-cadeau-pour-femme/',
          },
          {
            label: 'Anniversaire de mariage',
            link: 'idee-cadeau-anniversaire-mariage/',
          },
          {
            label: 'Cadeaux de naissance',
            link: 'cadeau-naissance/',
          },
        ],
      },
    ],
  },
};
