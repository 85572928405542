export const merchSubCategoryV3 = {
  route: () => import('./MerchSubCategoryV3.vue'),
  propTypes: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      required: true,
    },
  },
  devProps: {
    items: [
      {
        label: 'Séjour',
        link: 'nos-smartbox/sejour/',
        images: {
          base: 'https://media.smartbox.com/media/war/2020/new-homepage/Inspiration/SBX_CAT_Widget_Stays1_192x144.jpg',
        },
      },
      {
        label: 'Bien-être',
        link: 'nos-smartbox/bien-etre/',
        images: {
          base: 'https://media.smartbox.com/media/war/2020/new-homepage/Inspiration/SBX_CAT_Widget_Wellness2_192x144.jpg',
        },
      },
      {
        label: 'Gastronomie',
        link: 'nos-smartbox/gastronomie/',
        images: {
          base: 'https://media.smartbox.com/media/war/2020/new-homepage/Inspiration/IT_Gastro_Widget_192x144.jpg',
        },
      },
      {
        label: 'Sports & aventures',
        link: 'nos-smartbox/sport-et-aventure/',
        images: {
          base: 'https://media.smartbox.com/media/war/2020/new-homepage/Inspiration/SBX_CAT_Widget_Adventure1_192x144.jpg',
        },
      },
      {
        label: 'Loisirs',
        link: 'multi-themes/sorties-culturelles/',
        images: {
          base: 'https://media.smartbox.com/media/war/2020/new-homepage/Inspiration/FR_MTT_Widget_192x144.jpg',
        },
      },
      {
        label: 'Idées cadeaux',
        link: 'idee-cadeau-originale/',
        images: {
          base: 'https://media.smartbox.com/media/war/2020/new-homepage/Inspiration/IT_GiftIdeas_Widget_192x144.jpg',
        },
      },
    ],
  },
};
