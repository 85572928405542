export const testimonials = {
  route: () => import('./Testimonials.vue'),
  propTypes: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      required: true,
    },
  },
  devProps: {
    items: [
      {
        reviewTitle: 'Domaine Besancenot',
        reviewDescription:
          'Super accueil, chaleureux et convivial nous avons appris plein de choses et avons reçu un support très instructif et le vin était excellent ! Merci encore au Domaine Besancenot !',
        reviewAuthor: 'FRANCA.GALANTE',
        reviewBgColor: '#00963D',
        images: {
          mobile: 'https://media.smartbox.com/media/../media/it/static/homepage/2019/IT_Review_1_390x320.jpg',
          base: 'https://media.smartbox.com/media/../media/it/static/homepage/2019/IT_Review_1_390x320.jpg',
        },
      },
      {
        reviewTitle: 'Escape game',
        reviewDescription: 'Escape game top , très bonne organisation et super accueil. A faire en famille ou entre amis ! Super',
        reviewAuthor: 'NATHLAROCHE62',
        reviewBgColor: '#ffd300 ',
        images: {
          mobile: 'https://media.smartbox.com/media/war/2020/new-homepage/testimonials/FR_Testimonials_Castle_390x320.jpg',
          base: 'https://media.smartbox.com/media/war/2020/new-homepage/testimonials/FR_Testimonials_Castle_390x320.jpg',
        },
      },
      {
        reviewTitle: 'Institut des Lys',
        reviewDescription:
          "L'institut des Lys, une bulle magique de détente ! Massage prévue lors d'un week end en amoureux avec la smartbox et c'était extra...",
        reviewAuthor: 'OPHELIE.GAYAT',
        reviewBgColor: '#b1dde8',
        images: {
          mobile: 'https://media.smartbox.com/media/../media/it/static/homepage/2019/IT_Review_6_390x320.jpg',
          base: 'https://media.smartbox.com/media/../media/it/static/homepage/2019/IT_Review_6_390x320.jpg',
        },
      },
      {
        reviewTitle: 'HMC Lohéac',
        reviewDescription:
          "Très bonne première approche d'une expérience sur circuit, avec un accueil sympathique et un encadrement compétent et agréable...",
        reviewAuthor: 'QUERELLOU.A',
        reviewBgColor: '#00963D',
        images: {
          mobile: 'https://media.smartbox.com/media/war/2020/new-homepage/testimonials/FR_Testimonials_Wine_390x320.jpg',
          base: 'https://media.smartbox.com/media/war/2020/new-homepage/testimonials/FR_Testimonials_Wine_390x320.jpg',
        },
      },
      {
        reviewTitle: 'France Montgolfières Forcalquier',
        reviewDescription:
          'Equipe très agréable, à l’écoute des client et connaissant parfaitement les lieux, la géographie ainsi que la technique du vol en montgolfière...',
        reviewAuthor: 'LOURS04',
        reviewBgColor: '#ffd300',
        images: {
          mobile: 'https://media.smartbox.com/media/war/2020/new-homepage/testimonials/FR_Testimonials_EscapeGame_390x320.jpg',
          base: 'https://media.smartbox.com/media/war/2020/new-homepage/testimonials/FR_Testimonials_EscapeGame_390x320.jpg',
        },
      },
      {
        reviewTitle: 'Saut en parachute',
        reviewDescription:
          "L'accueil d'Xdream était parfait, l'ambiance générale dans l'aeroclub (Peronne -Estrees Mons) très agréable. Et le saut..indescriptible !!!",
        reviewAuthor: 'SABINETINAM.LIEPA',
        reviewBgColor: '#009ee3',
        images: {
          mobile: 'https://media.smartbox.com/media/../media/it/static/homepage/2019/IT_Review_5_390x320_v2.jpg',
          base: 'https://media.smartbox.com/media/../media/it/static/homepage/2019/IT_Review_5_390x320_v2.jpg',
        },
      },
    ],
  },
};
