export const merchSubCategoryV5 = {
  route: () => import('./MerchSubCategoryV5.vue'),
  propTypes: {
    items: {
      type: Array,
      required: true,
    },
  },
  devProps: {
    items: [
      {
        label: 'Compleanno',
        image: 'https://media.smartbox.com/media/ez/static/homepage/icons/E3_birthday_100x50.png',
        link: 'Compleanno/',
      },
      {
        label: 'Cadeaux couple',
        image: 'https://media.smartbox.com/media/ez/static/homepage/icons/E3_couple_100x50.png',
        link: 'coffret-cadeau-couple/',
      },
      {
        label: 'Cadeaux couple 1',
        image: 'https://media.smartbox.com/media/ez/static/homepage/icons/him2_100x50.png',
        link: 'coffret-cadeau-couple-1/',
      },
      {
        label: 'Cadeaux couple 2',
        image: 'https://media.smartbox.com/media/ez/static/homepage/icons/E3_her_100x50.png',
        link: 'coffret-cadeau-couple-2/',
      },
    ],
  },
};
