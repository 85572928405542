/* eslint-disable no-param-reassign */
import { Thumbor } from './thumbor';

export function initLazyLoading(image) {
  const lazyloadImage = image ? image : document.querySelector('.lazy');
  if (!lazyloadImage) {
    return;
  }

  if ('IntersectionObserver' in window) {
    //config to trigger the observer’s callback immediately after having an intersection
    const options = {
      root: null,
      rootMargin: '50px 0px',
      threshold: 0,
    };

    const imageObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const image = entry.target;
          image.src = image.dataset.src;
          image.classList.remove('lazy');
          imageObserver.unobserve(image);
        }
      });
    }, options);

    imageObserver.observe(lazyloadImage);
  } else {
    let lazyloadThrottleTimeout;

    // eslint-disable-next-line no-inner-declarations
    function lazyload() {
      if (lazyloadThrottleTimeout) {
        clearTimeout(lazyloadThrottleTimeout);
      }

      lazyloadThrottleTimeout = setTimeout(() => {
        const scrollTop = window.pageYOffset;
        if (lazyloadImage.offsetTop < window.innerHeight + scrollTop) {
          lazyloadImage.src = lazyloadImage.dataset.src;
          lazyloadImage.classList.remove('lazy');
        }
        if (!lazyloadImage) {
          document.removeEventListener('scroll', lazyload);
          window.removeEventListener('resize', lazyload);
          window.removeEventListener('orientationChange', lazyload);
        }
      }, 20);
    }

    document.addEventListener('scroll', lazyload);
    window.addEventListener('resize', lazyload);
    window.addEventListener('orientationChange', lazyload);
  }
}

// Debounce
export function debounce(callback, time = 100) {
  let timer = null;
  if (timer) clearTimeout(timer);
  timer = setTimeout(callback, time);
}

export const lazyPlaceholder = 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==';

export const generateNewUrl = (url, width = 640, height = 480) => {
  // Clunky function, want this tidying up in a dedicated component
  const basePath = url.substring(0, url.lastIndexOf('/') + 1);
  const imgPath = url.substring(url.lastIndexOf('/') + 1, url.length);
  const thumbor = new Thumbor('', basePath);
  const thumborize = thumbor
    //.filter('format(webp)') //remove format webp as it doesn't render quite good for png
    .setImagePath(imgPath)
    .resize(width, height)
    .buildUrl();
  const finalUrl = thumborize.replace('/unsafe/', '');
  return finalUrl;
};

export const findImageAlt = element => {
  const allImages = element.querySelectorAll('img');
  if (!allImages.length) {
    return true;
  }
  for (const img of allImages) {
    if (!img.hasAttribute('alt')) {
      return false;
    }
    return true;
  }
};
