import { brandingImporter } from './';
import { baseUrl } from './urlUtils';

const numberProductsToShow = 4; // 4 last recently viewed boxes
const ttl = 1209600000; // two weeks long

/**
 * Gets the browser cookie from the DOM and returns it as a string
 * @function getCookie
 * @param {string} - Name
 * @return {string} value
 */
export function getCookie(name) {
  return document.cookie.split(';').find(item => item.trim().startsWith(name));
}

export function getCookieValue(name) {
  const cookieValue = document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)');
  return cookieValue ? cookieValue.pop() : '';
}

/**
 * Sets the browser cookie in the DOM. This modifies the DOM directly.
 * @function setCookie
 * @param {string} - name
 * @param {string} - value
 * @param {string} - expiry date
 */
export function setCookie(name, value, expires = null) {
  window.document.cookie = `${name}=${value}; Path=/; ${expires ? expires : ''}`;
}

/**
 * Removes the browser cookie in the DOM. This modifies the DOM directly.
 * @function removeCookie
 * @param {string} - name
 */
export function removeCookie(name) {
  document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
}

function nowTimestamp() {
  return new Date().getTime();
}

function storeKey() {
  const url = baseUrl();
  const { contextKey } = brandingImporter({ url });
  return `recentlyViewed_${contextKey}`;
}

export function getRecentlyViewedFromCookie() {
  const viewedItemsData = getCookie(storeKey());
  let viewedItems =
    viewedItemsData !== undefined && viewedItemsData !== '' ? JSON.parse(viewedItemsData.replace(`${storeKey()}=`, '')) : [];

  //Remove expired items
  if (viewedItems.length) {
    viewedItems = viewedItems.filter(item => nowTimestamp() - item.timestamp <= ttl);
  }
  return viewedItems;
}

export function setRecentlyViewedToCookie(pimId) {
  const viewedItems = getRecentlyViewedFromCookie();
  const removeIndex = viewedItems.findIndex(item => item.id === pimId);

  if (removeIndex !== -1) {
    viewedItems.splice(removeIndex, 1);
  } else if (viewedItems.length >= numberProductsToShow) {
    viewedItems.pop();
  }

  //Add current box
  viewedItems.unshift({
    id: pimId,
    timestamp: nowTimestamp(),
  });

  setCookie(storeKey(), JSON.stringify(viewedItems), ttl);
}

export function getTokenFromCookie() {
  const url = baseUrl();
  const { cookieKey } = brandingImporter({ url });
  const token = getCookieValue(cookieKey);
  return token ? `Bearer ${token}` : '';
}
