import { articleList } from './ArticleList';
import { chevronLinks } from './ChevronLinks';
import { countdownTimer } from './CountdownTimer';
import { dummyBrochure } from './DummyBrochure';
import { footer } from './Footer';
import { heroBanner } from './HeroBanner';
import { heroBannerCarousel } from './HeroBannerCarousel';
import { highlightedProduct } from './HighlightedProduct';
import { landingBanner } from './LandingBanner';
import { landingFAQ } from './LandingFAQ';
import { merchSubCategoryV1 } from './MerchSubCategoryV1';
import { merchSubCategoryV2 } from './MerchSubCategoryV2';
import { merchSubCategoryV3 } from './MerchSubCategoryV3';
import { merchSubCategoryV4 } from './MerchSubCategoryV4';
import { merchSubCategoryV5 } from './MerchSubCategoryV5';
import { merchSubCategoryV6 } from './MerchSubCategoryV6';
import { multiLinkBlocks } from './MultiLinkBlocks';
import { newsletter } from './Newsletter';
import { newsletterPopup } from './NewsletterPopup';
import { occasions } from './Occasions';
import { productList } from './ProductList';
import { productListCarousel } from './ProductListCarousel';
import { promotionBanner } from './PromotionBanner';
import { recentlyViewed } from './RecentlyViewed';
import { seoLinkRepository } from './SeoLinkRepository';
import { subCategoriesLinks } from './SubCategoriesLinks';
import { subCategoriesLinksV2 } from './SubCategoriesLinksV2';
import { testimonials } from './Testimonials';
import { top10 } from './Top10';
import { uspRibbon } from './UspRibbon';
import { merchThinBlock } from './MerchThinBlock';
import { usp } from './Usp';
import { doubleMerchBlock } from './DoubleMerchBlock';

export default {
  articleList,
  chevronLinks,
  countdownTimer,
  doubleMerchBlock,
  dummyBrochure,
  footer,
  heroBanner,
  heroBannerCarousel,
  highlightedProduct,
  landingBanner,
  landingFAQ,
  merchSubCategoryV1,
  merchSubCategoryV2,
  merchSubCategoryV3,
  merchSubCategoryV4,
  merchSubCategoryV5,
  merchSubCategoryV6,
  merchThinBlock,
  multiLinkBlocks,
  newsletter,
  newsletterPopup,
  occasions,
  productList,
  productListCarousel,
  promotionBanner,
  recentlyViewed,
  seoLinkRepository,
  subCategoriesLinks,
  subCategoriesLinksV2,
  testimonials,
  top10,
  usp,
  uspRibbon,
};
