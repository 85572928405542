export const top10 = {
  route: () => import('./Top10.vue'),
  propTypes: {
    pimId: {
      type: String,
      required: true,
    },
    backgroundColor: {
      type: String,
      default: '#fff',
    },
    titlePrefix: {
      type: String,
      default: '',
    },
    titleColor: {
      type: String,
      default: '',
    },
    imagePosition: {
      type: String,
      default: 'left',
    },
    buttonLink: {
      type: String,
      default: '',
    },
    buttonTitle: {
      type: String,
      default: '',
    },
  },
  devProps: {
    pimId: '1412020',
  },
};
