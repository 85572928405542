import { environmentMapper, environments } from './localization/environments';

/**
 * Object containing prodHosts. Used in environmentMapper(), SbxPCC_Wrapper.vue. SbxSc_Wrapper.vue
 * @constant prodHosts
 */
export const prodHosts = [
  'www.smartbox.com',
  'www.dakotabox.es',
  'www.dakotabox.fr',
  'www.emozione3.it',
  'www.lavidaesbella.es',
  'www.bongo.nl',
  'www.bongo.be',
  'www.cadeaubox.be',
  'pcs-uat',
  'checkout.smartbox.com',
  'checkout.dakotabox.es',
  'checkout.dakotabox.fr',
  'checkout.emozione3.it',
  'checkout.lavidaesbella.es',
  'checkout.bongo.nl',
  'checkout.bongo.be',
  'checkout.cadeaubox.be',
  'pcs-uat-nuxt3.bongo.be',
  'pcs-uat-nuxt3.bongo.nl',
  'pcs-uat-nuxt3.cadeaubox.be',
  'pcs-uat-nuxt3.dakotabox.fr',
  'pcs-uat-nuxt3.dakotabox.es',
  'pcs-uat-nuxt3.emozione3.it',
  'pcs-uat-nuxt3.lavidaesbella.es',
  'pcs-uat-nuxt3.smartbox.com',
];

/**
 * Object containing preProdHosts. Used in environmentMapper()
 * @constant preProdHosts
 */
export const preProdHosts = [
  'front-pp-u3.smartbox.com',
  'front-royal-pp-u3.smartbox.com',
  'preprod.dakotabox.es',
  'preprod.dakotabox.fr',
  'preprod.emozione3.it',
  'preprod.lavidaesbella.es',
  'preprod.bongo.nl',
  'preprod.bongo.be',
  'preprod.cadeaubox.be',
  'web.gitlab.production.smartbox.com',
  'pp-checkout.smartbox.com',
  'pp-checkout.bongo.be',
  'pp-checkout.bongo.nl',
  'pp-checkout.cadeaubox.be',
  'pp-checkout.dakotabox.fr',
  'pp-checkout.dakotabox.es',
  'pp-checkout.emozione3.it',
  'pp-checkout.lavidaesbella.es',
];

/**
 * pattern which is maps domain to local host regrex. Used in environmentMapper()
 * @constant localHostPatterns
 * @private
 */
export const localHostPatterns = [/localhost:[0-9]{4}/, /dev\.[a-z]/, /local\.search-components\.smartbox\.com/];

/**
 * Object which is maps domain to branding key
 * @constant additionDomainsToLangMap
 * @private
 */
export const additionDomainsToLangMap = {
  'com/fr/': 'fr',
  'com/es/': 'es',
  'com/it/': 'it',
  'com/de/': 'de',
  'com/dk/': 'da',
  'com/se/': 'sv',
  'com/ch/': 'de',
  'com/ch/fr/': 'fr',
  'com/ch/it/': 'it',
  'com/ch/de/': 'de',
  'es/': 'es',
  'fr/': 'fr',
  'it/': 'it',
  'be/': 'nl',
  'nl/': 'nl',
  'net/de/': 'de',
  'be/fr/': 'fr',
  'be/nl/': 'nl',
};

/**
 * Object which is maps domain to branding key. Used in brandingImporter()
 * @constant domainBrandingMap
 * @private
 */
export const domainBrandingMap = {
  'smartbox.com/': 'sbx',
  'dakotabox.es/': 'dakEs',
  'dakotabox.fr/': 'dakFr',
  'emozione3.it/': 'emo',
  'lavidaesbella.es/': 'lav',
  'bongo.nl/': 'bon',
  'bongo.be/': 'bon',
  'cadeaubox.be/': 'cbx',
};

/**
 * Array of languages. Used in brandingImporter()
 * @constant languageList
 * @private
 */
export const languageList = ['en', 'fr', 'es', 'it', 'de', 'nl', 'sv', 'da'];

/**
 * Array of urlPatterns. Used in brandingImporter()
 * @constant urlPatterns
 * @private
 */
const urlPatterns = [
  /https?:\/\/[^/]+\/(ch\/it|ch\/fr|ch\/de)$/i,
  /https?:\/\/[^/]+\/(ch\/it|ch\/fr|ch\/de)\//i,
  /https?:\/\/[^/]+\/(ch\/it|ch\/fr|ch\/de)\?/i,
  /https?:\/\/[^/]+\/(cb\/fr|cb\/nl)$/i,
  /https?:\/\/[^/]+\/(cb\/fr|cb\/nl)\//i,
  /https?:\/\/[^/]+\/(cb\/fr|cb\/nl)\?/i,
  /https?:\/\/[^/]+\/(bb\/fr|bb\/nl)$/i,
  /https?:\/\/[^/]+\/(bb\/fr|bb\/nl)\//i,
  /https?:\/\/[^/]+\/(bb\/fr|bb\/nl)\?/i,
  /https?:\/\/[^/]+\/[a-z]{2}$/i,
  /https?:\/\/[^/]+\/[a-z]{2}\//i,
  /https?:\/\/[^/]+\/[a-z]{2}\?/i,
  /https?:\/\/[^/]+/i,
];

const createUrlPrefix = (urlPrefix, extUrl, isEmptyPathName = false) => {
  const extBaseUrl = new URL(extUrl);
  const hostname = extBaseUrl.hostname.split('.');
  hostname[0] = urlPrefix;
  extBaseUrl.hostname = hostname.join('.');
  if (isEmptyPathName) {
    extBaseUrl.pathname = '';
  }
  return extBaseUrl.href;
};

const mapM2DevBaseUrl = {
  '/bb': 'bongo-be.ie1-prj-k8s-01.sbxtest.net',
  '/bn': 'bongo-nl.ie1-prj-k8s-01.sbxtest.net',
  '/cb': 'cadeaubox-be.ie1-prj-k8s-01.sbxtest.net',
  '/df': 'dakotabox-fr.ie1-prj-k8s-01.sbxtest.net',
  '/ds': 'dakotabox-es.ie1-prj-k8s-01.sbxtest.net',
  '/ez': 'emozione3-it.ie1-prj-k8s-01.sbxtest.net',
  '/lv': 'lavidaesbella-es.ie1-prj-k8s-01.sbxtest.net',
};
const mapM2DevApiUrl = {
  '/bb': 'bongo-be-admin.ie1-prj-k8s-01.sbxtest.net',
  '/bn': 'bongo-nl-admin.ie1-prj-k8s-01.sbxtest.net',
  '/cb': 'cadeaubox-be-admin.ie1-prj-k8s-01.sbxtest.net',
  '/df': 'dakotabox-fr-admin.ie1-prj-k8s-01.sbxtest.net',
  '/ds': 'dakotabox-es-admin.ie1-prj-k8s-01.sbxtest.net',
  '/ez': 'emozione3-it-admin.ie1-prj-k8s-01.sbxtest.net',
  '/lv': 'lavidaesbella-es-admin.ie1-prj-k8s-01.sbxtest.net',
};

/**
 * This returns the baseUrl for of the site the component is running on. It is matched against regrex urlPatterns
 or fallsback to a relative path. Eg. this can be used in javascript events or Ajax calls.
 * @function baseUrl
 * @url string | null
 * @example
 * https://www.smartbox.com/ch/it/nostri-smartbox/soggiorni/fuga-romantica-in-svizzera-2-notti-in-b-b-per-2-persone-1339018.html
 * // returns https://www.smartbox.com/ch/it/
 * https://www.bongo.be/nl/cadeaubonnen/overnachten/lang-weekend-met-ontbijt-en-fietsenverhuur-in-amsterdam-1390997.html
 * // return https://www.bongo.be/nl/
 * @returns  {String} Returns the baseUrl of the component
 */
export function baseUrl(urlProp = null) {
  let url = '';
  /* eslint-disable no-useless-escape */

  if (!process.server) {
    // eslint-disable-next-line no-undef
    if (globalThis && globalThis.baseUrl) {
      // eslint-disable-next-line no-undef
      url = globalThis.baseUrl;
    }
  }
  if (urlProp) {
    url = urlProp;
  } else {
    const devMode =
      process.env.NODE_ENV === 'development' ||
      window.location.hostname == 'localhost' ||
      window.location.hostname == 'pcc.defuse.sbxtest.net' ||
      window.location.hostname == 'sc.defuse.sbxtest.net' ||
      window.location.pathname == '/precart-components/iframe.html';

    url = devMode && sessionStorage.getItem('baseUrlOveride') !== null ? sessionStorage.getItem('baseUrlOveride') : window.location.href;
  }

  for (let i = 0; i < urlPatterns.length; i++) {
    const match = url.toLowerCase().match(urlPatterns[i]);
    if (match) return match[0].replace(/(\?|\/|\/qr\/)$/, '') + '/';
  }
  return '/'; // fallback to relative path
  // return ''
}

export const m2BaseUrl = (url = null, env = null) => {
  const absoluteUrl = baseUrl(url);
  const envOverride = env || environmentMapper();
  //Check External Domain
  if ([environments.production, environments.preproduction].includes(envOverride)) {
    const externalDomains = [...preProdHosts, ...prodHosts];

    if (externalDomains.filter(x => absoluteUrl.includes(x)).length) {
      const m2Prefix = environments.preproduction == envOverride ? 'pp-checkout' : 'checkout';
      return createUrlPrefix(m2Prefix, absoluteUrl);
    }
  }

  const devBaseUrl = () => {
    //Calcuate fallback url
    const m2DevBaseUrl = new URL(absoluteUrl);
    m2DevBaseUrl.hostname = 'smartbox-com.ie1-prj-k8s-01.sbxtest.net';
    for (const brandId of Object.keys(mapM2DevBaseUrl)) {
      if (m2DevBaseUrl.pathname.match(brandId)) {
        m2DevBaseUrl.hostname = mapM2DevBaseUrl[brandId];
        m2DevBaseUrl.pathname = m2DevBaseUrl.pathname.replace(brandId, '');
        m2DevBaseUrl.port = '';
      }
    }
    return m2DevBaseUrl.href;
  };

  // All other envs return devBaseUrl
  return devBaseUrl();
};

export const m2ApiUrl = (url = null, env = null) => {
  const absoluteUrl = baseUrl(url);
  const envOverride = env || environmentMapper();
  //Check External Domain
  if ([environments.production, environments.preproduction].includes(envOverride)) {
    const externalDomains = [...preProdHosts, ...prodHosts];

    if (externalDomains.filter(x => absoluteUrl.includes(x)).length) {
      const m2Prefix = environments.preproduction == envOverride ? 'pp-admin' : 'admin';
      return createUrlPrefix(m2Prefix, absoluteUrl);
    }
  }

  const devApiUrl = () => {
    //Calcuate fallback url
    const m2DevApiUrl = new URL(absoluteUrl);
    m2DevApiUrl.hostname = 'smartbox-com-admin.ie1-prj-k8s-01.sbxtest.net';
    for (const brandId of Object.keys(mapM2DevApiUrl)) {
      if (m2DevApiUrl.pathname.match(brandId)) {
        m2DevApiUrl.hostname = mapM2DevApiUrl[brandId];
        m2DevApiUrl.pathname = m2DevApiUrl.pathname.replace(brandId, '');
        m2DevApiUrl.port = '';
      }
    }
    return m2DevApiUrl.href;
  };

  // All other envs return devApiUrl
  return devApiUrl();
};

export const myaccountBaseUrl = (url = null, env = null) => {
  const absoluteUrl = baseUrl(url);
  const envOverride = env || environmentMapper();
  //Check External Domain
  if ([environments.production, environments.preproduction].includes(envOverride)) {
    const externalDomains = [...preProdHosts, ...prodHosts];

    if (externalDomains.filter(x => absoluteUrl.includes(x)).length) {
      const myaccountPrefix = environments.preproduction == envOverride ? 'myaccount-pp' : 'myaccount';
      return createUrlPrefix(myaccountPrefix, absoluteUrl);
    }
  }

  const devMyAccountBaseUrl = () => {
    //Calcuate fallback url
    const devMyAccountUrl = new URL(absoluteUrl);
    devMyAccountUrl.hostname = 'shire-ui-devint.ie1-prj-k8s-01.sbxtest.net';
    devMyAccountUrl.port = '';
    return devMyAccountUrl.href;
  };
  // All other envs return devMyAccountBaseUrl
  return devMyAccountBaseUrl();
};

export const myaccountApiUrl = (url = null, env = null) => {
  const absoluteUrl = baseUrl(url);
  const envOverride = env || environmentMapper();
  //Check External Domain
  if ([environments.production, environments.preproduction].includes(envOverride)) {
    const externalDomains = [...preProdHosts, ...prodHosts];

    if (externalDomains.filter(x => absoluteUrl.includes(x)).length) {
      const myaccountApiPrefix = environments.preproduction == envOverride ? 'myaccount-api-pp' : 'myaccount-api';
      return createUrlPrefix(myaccountApiPrefix, absoluteUrl, true);
    }
  }

  const devMyAccountApiUrl = () => {
    //Calcuate fallback url
    const devMyAccountUrl = new URL(absoluteUrl);
    devMyAccountUrl.hostname = 'shire-api-devint-public.ie1-prj-k8s-01.sbxtest.net';
    devMyAccountUrl.pathname = '';
    devMyAccountUrl.port = '';
    return devMyAccountUrl.href;
  };
  // All other envs return devMyAccountBaseUrl
  return devMyAccountApiUrl();
};
