import { brands, brandingThemes, locales, domainSearchBrandingMap, localeMap } from './localization/brands';
import { getLocaleCurrencyFormat } from './currencies/currencies';

import { getSearchURL } from './searchApiUtils';
import { baseUrl } from './urlUtils';

/**
 * @function brandingImporter
 * @description This function returns the brandingObject as determined from the URL
 * @return {Object}  brandingObject
 * @property {Object} styles - The brand styles as designated in localization brandingThemes[brand]
 * @property {string} brand  - The brand as a string returned from getBrand()
 * @property {string} locale - The locale as a string returned from getLocale() i.e. fr-FR, etc
 * @property {Boolean} isPrimaryBrand - The boolean returned from isPrimaryBrand()
 * @property {Object} contextHeaders - The object returns the required headers object for using the Search API `{ brand, language, country }`
 * @property {string} contextKey - Context key use in branding items object for storing data
 * @property {Object} currency - The object returned getLocaleCurrencyFormat() `{ "brand", "locale", "currency", "currencyStyle", "delimiter" , "thousands" }`
 * @property {string} cookieKey - Cookie key used in recently viewed products
 * @property {Object} searchURLHeaders - The object returns the required headers object for using the Search API `{ brand, language, country }`
 * @property {string} searchURL - URL used for calling search API
 * @example
 * {
 "styles": {
 "--brand-theme": "sbx",
 "--brand-primary-color-1": "#EB5F40",
 "--brand-highlight-card-background": "#FFF8EA",
 "--brand-section-background": "#F9F6F6",
 "--brand-icon-grey-shade-1": "#777777",
 "--brand-secondary-icon-green": "unset",
 "--brand-search-bar-background": "#fff",
 "--brand-newsletter-footer-background": "#4C509D",
 "--brand-footer-background": "#EEECE8",
 "--brand-footer-title": "#6e6963",
 "--brand-footer-content": "#343533",
 "--brand-footer-icon": "#f4f0ed",
 "--brand-dark-blue": "#4C509D",
 "--brand-light-blue": "#B1DDE8",
 "--brand-dark-green": "#006648",
 "--brand-bright-green": "#009847",
 "--brand-light-green": "#D1D700",
 "--brand-yellow-shade-1": "#FFD300",
 "--brand-pink": "#F7C4DC",
 "--brand-yellow-shade-2": "#FFE598",
 "--brand-purple": "#7E2182",
 "--brand-font-regular": "smartbox",
 "--brand-font-bold": "smartbox-bold",
 "--brand-font-medium": "smartbox-medium"
 },
 "brand": "sbx",
 "locale": "fr-FR",
 "isPrimaryBrand": true,
 "contextHeaders": {
 "brand": "sbx",
 "language": "fr",
 "country": "fr"
 },
 "contextKey": "SBX-FR-fr-",
 "currency": {
 "brand": "sbx",
 "locale": "fr-FR",
 "currency": "€",
 "currencyStyle": "postS",
 "delimiter": ",",
 "thousands": " "
 },
 "cookieKey": "SBX_FR",
 "searchURLHeaders": {
 "brand": "sbx",
 "language": "fr",
 "country": "fr"
 },
 "searchURL": "search.smartbox.com",
 }
 */
export const brandingImporter = ({ brandOverride = null, localeOverride = null, url = null } = {}) => {
  const locale = getLocale(localeOverride, url);
  const brand = getBrand(brandOverride, url);
  return {
    styles: brandingThemes[brand],
    brand,
    locale,
    isPrimaryBrand: isPrimaryBrand(brand),
    contextHeaders: {
      brand: brand.toLowerCase().substring(0, 3),
      language: fromLocaleToLangCode(locale).toLowerCase(),
      country: fromLocaleToCountryCode(locale).toLowerCase(),
    },
    contextKey:
      brand.toUpperCase().substring(0, 3) + '-' + fromLocaleToCountryCode(locale) + '-' + fromLocaleToLangCode(locale).toLowerCase() + '-',
    currency: getLocaleCurrencyFormat(brand, locale),
    cookieKey: brand.toUpperCase().substring(0, 3) + '_' + fromLocaleToCountryCode(locale),
    searchURLHeaders: {
      brand: brand.toLowerCase().substring(0, 3),
      language: fromLocaleToLangCode(locale).toLowerCase(),
      country: fromLocaleToCountryCode(locale).toLowerCase(),
    },
    searchURL: getSearchURL(url),
  };
};

const defaultLocale = locales.french;
const defaultBrand = brands.smartbox;

/**
 * @description Checks if brand is primary (bongo or smartbox). Used in brandingImporter()
 * @private
 */
function isPrimaryBrand(brand) {
  return brand === brands.smartbox || brand === brands.bongo;
}

/**
 * @description Gets brand from url / sessionOverride. Use in brandingImporter()
 * @private
 */
function getBrand(override = null, url = null) {
  // Make sure we always return a valid brand
  if (override) {
    if (Object.values(brands).includes(override)) {
      return override;
    }
    return defaultBrand;
  }

  const baseURL = baseUrl(url);

  for (const [brand, urlExpressions] of Object.entries(domainSearchBrandingMap)) {
    if (urlExpressions.find(expr => expr.test(baseURL))) {
      return brand;
    }
  }
  return defaultBrand;
}

/**
 * @description Gets locale from url / sessionOverride. Use in brandingImporter()
 * @private
 */
function getLocale(override = null, url = null) {
  // Make sure we always return a valid locale
  if (override) {
    if (Object.values(locales).includes(override)) {
      return override;
    }
    return defaultLocale;
  }

  const baseURL = baseUrl(url);
  for (const [locale, urlExpressions] of Object.entries(localeMap)) {
    if (urlExpressions.find(expr => expr.test(baseURL))) {
      return locale;
    }
  }
  return defaultLocale;
}

/**
 * @description Converts locale to country code. Use in brandingImporter()
 * @private
 */
export function fromLocaleToCountryCode(locale) {
  if (!locale) return locale;
  return locale.split('-')[1];
}

/**
 * @description Converts country code to locale. Use in brandingImporter()
 * @private
 */
export function fromLocaleToLangCode(locale) {
  if (!locale) return locale;
  return locale.split('-')[0];
}

export function dynamicBrandTranslation(trans) {
  const { brand, contextHeaders } = brandingImporter();
  const brandTrans = `${trans}.${brand}`;
  // $te is a method which checks the exist of the key, in this case if a branded key exists
  const brandCheck = this.$te(brandTrans, contextHeaders.language);
  return brandCheck ? brandTrans : trans;
}
