const fontFaces = `
  @font-face {
    font-family: 'din';
    src: url('//media.smartbox.com/media/fonts/DINWeb.eot?#iefix') format('embedded-opentype'),
         url('//media.smartbox.com/media/fonts/DINWeb.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'din-bold';
    src: url('//media.smartbox.com/media/fonts/DINWeb-Bold.eot?#iefix') format('embedded-opentype'),
         url('//media.smartbox.com/media/fonts/DINWeb-Bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'din-medium';
    src: url('//media.smartbox.com/media/fonts/DINWeb-Medium.eot?#iefix') format('embedded-opentype'),
         url('//media.smartbox.com/media/fonts/DINWeb-Medium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'smartbox-icons-extended';
    src: url('//media.smartbox.com/media/fonts/smartbox-icons-extended-webfont.eot');
    src: url('//media.smartbox.com/media/fonts/smartbox-icons-extended-webfont.eot?#iefix') format('embedded-opentype'),
    url('//media.smartbox.com/media/fonts/smartbox-icons-extended-webfont.woff2') format('woff2'),
    url('//media.smartbox.com/media/fonts/smartbox-icons-extended-webfont.woff') format('woff'),
    url('//media.smartbox.com/media/fonts/smartbox-icons-extended-webfont.ttf') format('truetype'),
    url('//media.smartbox.com/media/fonts/smartbox-icons-extended-webfont.svg#smartbox-iconsregular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'smartbox';
    src: url('//media.smartbox.com/media/fonts/Monts-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

  @font-face {
    font-family: 'smartbox-bold';
    src: url('//media.smartbox.com/media/fonts/Monts-Bold.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'smartbox-medium';
    src: url('//media.smartbox.com/media/fonts/Monts-Med.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

`;

/**
 * This checks if fontFaces have been loaded into the DOM and loads if neccesary. It directly modifies the DOM object
 * @function addFontsStyleTag
 productList.js */
export function addFontsStyleTag() {
  if (window.sbxComponents) {
    window.sbxComponents++;
    return;
  }

  window.sbxComponents = 1;
  // add the style tag
  const style = document.createElement('style');
  style.dataset.description = 'sbx-font-faces';

  style.innerHTML = fontFaces;
  document.head.appendChild(style);
}

/**
 * This checks if fontFaces have been loaded into the DOM and removes if neccesary. It directly modifies the DOM object
 * @function removeFontsStyleTag
 */
export function removeFontsStyleTag() {
  window.sbxComponents--;

  if (!window.sbxComponents) {
    // remove the style tag
    const style = document.querySelector('style[data-description="sbx-font-faces"]');

    document.head.removeChild(style);
  }
}
