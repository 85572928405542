export const productVignette = {
  $t: () => {},
  route: () => import('./ProductVignette.vue'),
  propTypes: {
    product: {
      type: Object,
      default: {
        id: 1,
        name: 'Séjour gastronomique au château pour un duo bon vivant',
        url: 'https://www.smartbox.com/fr/nos-smartbox/sejour/sejour-golf-et-spa-en-hotel-4-a-chantilly-1396430.html',
        gallery: {
          items: ['https://media.smartbox.com/pim/1000002206762485306089.jpg'],
        },
        rating: 8.48,
        ratingClass: 'rating__star rating__star-4',
        reviewsCount: 659,
        isSev: false,
        isFormatEvoucherBuyer: false,
        isFormatPhysical: true,
        isFormatPof: false,
        personCount: '2',
        price: '239.90',
        regions: [
          {
            name: 'Aquitaine',
            code: 'FR-B',
          },
        ],
        subRegions: [
          {
            name: 'Ariège',
            code: 'FR-09',
          },
        ],
        subtitle: '92 séjours gastronomiques dans un château',
        shortDescription: '1 dîner gastronomique et 1 nuit au château avec petit-déjeuner pour 2 personnes',
      },
    },
    related: {
      type: Object,
      default: {
        buyer_discount: {
          currency_code: 'EUR',
          discount_type: 'percentage',
          discounted_price: 203.92,
          id: 1258488,
          total_discount: 18,
          free_shipping: true,
        },
      },
    },
    vignetteHandleClick: {
      type: Function,
      default() {
        return {};
      },
    },
    testId: {
      type: String,
      default: 'sbx-pcc_productList-list-item-0',
    },
  },
};
