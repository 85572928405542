export const footer = {
  route: () => import('./Footer.vue'),
  propTypes: {
    footerLinks: {
      type: Array,
      required: true,
    },
    payments: {
      type: Object,
      default: { title: '', items: [{ label: '', class: '' }] },
    },
    showCountrySelector: {
      type: String,
      default: 'true',
      required: true,
      validator: value => {
        return ['true', 'false'].includes(value);
      },
    },
    termsDescription: {
      type: String,
      default: '',
    },
  },
  devProps: {
    termsDescription:
      'Les produits physiques Smartbox sont proposés avec un livret qui vous permettra de découvrir un aperçu des expériences offertes par Smartbox. Retrouvez la liste complète des expériences et choisissez celle qui vous ressemble sur l’Appli Smartbox ou sur <a style="text-decoration: none; color: #343533;" href="https://www.smartbox.com/fr/">smartbox.com</a>.<br>*Voir conditions dans la section « Informations légales » puis « Conditions générales de vente et d’utilisation »<br>**Etude réalisée en <sup>juillet 2020</sup><br>***Sauf produits avec validité spécifique. Chaque date de validité est mentionnée sur la page dédiée du produit/partenaire<br><br><br>Disponibilités des séjours garanties toute l’année - Sur une sélection dynamique d\'hôtels, avec surcoût éventuel. Hors coffrets séjours bien-être et gastronomiques, et sous réserve de disponibilités suite à la crise <b>Covid-19</b>.<br>Aucune promotion active sur le site web de Smartbox (notamment, mais pas exclusivement, les codes promotionnels, le coffret cadeau gratuit à ajouter au panier, les produits avec un prix barré) ne peut être cumulée avec d\'autres promotions, codes promotionnels ou avoirs <i>Smartbox</i>.<br>De plus, toute promotion active sur le site web de Smartbox (notamment, mais pas exclusivement, les codes promotionnels, le coffret cadeau gratuit à ajouter au panier, les produits avec un prix barré) est applicable uniquement aux nouvelles commandes standard et les produits obtenus à la suite d\'échanges ou obtenus avec un avoir Smartbox ne sont pas éligibles.',
    footerLinks: [
      {
        title: "Besoin d'aide",
        items: [
          {
            link: 'https://faq.smartbox.com/s/?language=fr',
            label: 'Nous contacter',
            targetBlank: true,
          },
          {
            link: '/informations-legales',
            label: 'Informations légales',
          },
          {
            link: '/charte-de-protection-des-donnees-personnelles',
            label: 'Charte de protection des données personnelles',
          },
          {
            link: '/erratum-smartbox/',
            label: 'Erratum Smartbox',
          },
          {
            link: 'https://faq.smartbox.com/s/?language=fr#delivery-info',
            label: 'Informations sur la livraison',
          },
          {
            link: '/covid19',
            label: 'Garantie de sécurité COVID',
          },
        ],
      },
      {
        title: 'Votre smartbox',
        items: [
          {
            link: '/voucher/beneficiary/registerlogin',
            label: 'Échanger votre coffret cadeau',
          },
          {
            link: '/voucher/beneficiary/registerlogin',
            label: 'Réservation en ligne',
          },
          {
            link: '/track',
            label: 'Suivre votre commande',
          },
          {
            link: '/cgv',
            label: 'Bon cadeau',
          },
          {
            link: 'https://smartbox.lepotcommun.fr/',
            label: 'Créez votre cagnotte en ligne',
          },
          {
            link: 'https://apps.apple.com/fr/app/smartbox/id1350380905',
            src: 'http://media.smartbox.com/media/war/2021/app/FR_AppDownload_Apple_225x76.png',
          },
          {
            link: 'https://play.google.com/store/apps/details?id=com.smartbox.smartboxbeneficiary&hl=fr_US&gl=FR',
            src: 'http://media.smartbox.com/media/war/2021/app/FR_AppDownload_Google_225x67.png',
          },
        ],
      },
      {
        title: 'À propos',
        items: [
          {
            link: '/qui-sommes-nous',
            label: 'Qui sommes-nous ?',
          },
          {
            link: 'https://partners.smartbox-group.com/s/login/SelfRegister?language=fr',
            label: 'Devenir partenaire',
          },
          {
            link: '/programme-d-affiliation/',
            label: "Programme d'Affiliation et Influenceurs",
          },
          {
            link: '/points-de-vente/',
            label: 'Nos points de vente',
          },
          {
            link: 'https://smartbox.wd3.myworkdayjobs.com/en-US/smartbox_careers',
            label: 'Smartbox recrute',
            targetBlank: true,
          },
          {
            link: '/plan-du-site',
            label: 'Plan du Site',
          },
          {
            link: '/lastminute',
            label: 'lastminute.com',
          },
        ],
      },
      {
        title: 'Suivez nous',
        items: [
          {
            link: 'https://www.facebook.com/smartbox.france',
            label: 'Facebook',
            icon: 'si-facebook',
            targetBlank: true,
          },
          {
            link: '/blog',
            label: 'Blog Smartbox',
            icon: 'si-smartbox-blog',
            targetBlank: true,
          },
          {
            link: 'https://www.instagram.com/smartbox_fr/',
            label: 'Instagram',
            icon: 'si-instagram',
            targetBlank: true,
          },
        ],
      },
    ],
    payments: {
      title: 'Méthodes de paiement',
      items: [
        {
          label: 'mondial relay',
          class: 'delivery-mondial-relay',
        },
        {
          label: 'tnt',
          class: 'delivery-tnt',
        },
        {
          label: 'colissimo',
          class: 'delivery-colissimo',
        },
        {
          label: 'visa',
          class: 'payment-visa',
        },
        {
          label: 'mastercard',
          class: 'payment-mastercard',
        },
        {
          label: 'american-express',
          class: 'payment-american-express',
        },
        {
          label: 'carte-bleue',
          class: 'payment-cb',
        },
        {
          label: 'e-bleue',
          class: 'payment-carte-bleue',
        },
        {
          label: 'paypal',
          class: 'payment-paypal',
        },
        {
          label: 'gift-card',
          class: 'gift-card',
        },
        {
          label: 'le-pot-commun',
          class: 'payment-lpc',
        },
        {
          label: 'amazon-pay',
          class: 'payment-amazon-pay',
        },
        {
          label: 'apple-pay',
          class: 'payment-apple-pay',
        },
      ],
    },
  },
};
