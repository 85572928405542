export const doubleMerchBlock = {
  route: () => import('./DoubleMerchBlock.vue'),
  propTypes: {
    merchBlocks: {
      type: Array,
      required: true,
    },
  },
  devProps: {
    merchBlocks: [
      {
        link: '#link1',
        image: 'https://media.smartbox.com/media/it/war/test/SBX_BlackFriday21_Icon_2_60x100.png',
        icon: 'si-usp-secure-payment',
        iconSize: '44',
        iconColor: 'red',
        title: 'First block title',
        subtitle: 'First block subtitle. Some other text to make it to two lines. use some html also <b>bold</b> and <u>underline</u>',
        backgroundImage: '',
        backgroundColor: 'green',
        textColor: 'white',
      },
      {
        link: '#link2',
        image: '',
        icon: 'si-usp-validity',
        iconColor: 'green',
        iconSize: '39',
        title: 'Second block title',
        subtitle: 'Second block subtitle',
        backgroundImage: 'https://media.smartbox.com/media/war/stars.png',
        backgroundColor: 'black',
        textColor: 'red',
      },
    ],
  },
};
