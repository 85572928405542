import styles from '../../scss/brand.module.scss'; // named exports not working with vuecli5
const { collectionWhite, collectionBlack, collectionDarkgrey, collectionPromoRed2, collectionColourTurqouise1 } = styles;

/**
 Object of locales. Used in  brandingImporter()
 * @private
 */
export const locales = {
  french: 'fr-FR',
  italian: 'it-IT',
  spanish: 'es-ES',
  swissFrench: 'fr-CH',
  swissGerman: 'de-CH',
  swissItalian: 'it-CH',
  swedish: 'sv-SE',
  danish: 'da-DK',
  dutch: 'nl-NL',
  belgianDutch: 'nl-BE',
  belgianFrench: 'fr-BE',
  irish: 'en-IE',
  german: 'de-DE',
};

/***
 * @description Object of brands. Used in  supportsLocale()
 * @private
 */
export const brands = {
  smartbox: 'sbx',
  dakotaSpain: 'dakEs',
  dakotaFrance: 'dakFr',
  bongo: 'bon',
  emozione: 'emo',
  lavidaesbella: 'lav',
  cadeaubox: 'cbx',
};

/***
 * @description Object of brandingThemes. Used in  brandingImporter()
 * @private
 */
export const brandingThemes = {
  sbx: {
    '--brand-theme': 'sbx',
    '--brand-primary-color-1': '#EB5F40',
    '--brand-primary-color-darker-10': '#e03c18', //darken color 10%
    '--brand-primary-color-lighten-10': '#FBEEEE', //lighten color
    '--brand-highlight-card-background': '#FFF8EA',
    '--brand-section-background': '#F9F6F6',
    '--brand-icon-grey-shade-1': '#777777',
    '--brand-secondary-icon-green': 'unset',
    '--brand-search-bar-background': collectionWhite,
    '--brand-newsletter-footer-background': '#4C509D',
    '--brand-header-background': collectionWhite,
    '--brand-header-title': collectionDarkgrey,
    '--brand-header-color-1': '#EB5F40',
    '--brand-header-color-text-1': collectionWhite,
    '--brand-header-color-text-2': '#343533',
    '--brand-header-color-tooltip-text': '#343533',
    '--brand-megamenu-background': '#f9f6f6',
    '--brand-megamenu-title': collectionDarkgrey,
    '--brand-footer-background': '#EEECE8',
    '--brand-footer-title': '#6e6963',
    '--brand-footer-content': '#343533',
    '--brand-footer-icon': '#f4f0ed',
    '--brand-dark-blue': '#4C509D',
    '--brand-light-blue': '#B1DDE8',
    '--brand-dark-green': '#006648',
    '--brand-bright-green': '#009847',
    '--brand-light-green': '#D1D700',
    '--brand-yellow-shade-1': '#FFD300',
    '--brand-pink': '#F7C4DC',
    '--brand-yellow-shade-2': '#FFE598',
    '--brand-purple': '#7E2182',
    '--brand-font-regular': 'smartbox',
    '--brand-font-bold': 'smartbox-bold',
    '--brand-font-medium': 'smartbox-medium',
  },
  bon: {
    '--brand-theme': 'bon',
    '--brand-primary-color-1': '#AFBC22',
    '--brand-primary-color-darker-10': '#9da91e',
    '--brand-primary-color-lighten-10': '#F4F9ED',
    '--brand-highlight-card-background': '#FBFDEA',
    '--brand-section-background': '#F9F6F6',
    '--brand-icon-grey-shade-1': '#777777',
    '--brand-secondary-icon-green': '#00C4B3',
    '--brand-search-bar-background': collectionWhite,
    '--brand-newsletter-footer-background': '#4C509D',
    '--brand-header-background': collectionWhite,
    '--brand-header-title': '#343533',
    '--brand-header-color-1': '#AFBC22',
    '--brand-header-color-text-1': collectionWhite,
    '--brand-header-color-text-2': '#343533',
    '--brand-header-color-tooltip-text': '#343533',
    '--brand-megamenu-background': '#f9f6f6',
    '--brand-megamenu-title': '#343533',
    '--brand-footer-background': '#EEECE8',
    '--brand-footer-title': '#6e6963',
    '--brand-footer-content': '#343533',
    '--brand-footer-icon': '#f4f0ed',
    '--brand-dark-blue': '#4C509D',
    '--brand-light-blue': 'unset',
    '--brand-dark-green': 'unset',
    '--brand-bright-green': 'unset',
    '--brand-light-green': 'unset',
    '--brand-yellow-shade-1': '#FFD300',
    '--brand-pink': 'unset',
    '--brand-yellow-shade-2': 'unset',
    '--brand-purple': '#7E2182',
    '--brand-font-regular': 'smartbox',
    '--brand-font-bold': 'smartbox-bold',
    '--brand-font-medium': 'smartbox-medium',
  },
  cbx: {
    '--brand-theme': 'cbx',
    '--brand-primary-color-1': collectionPromoRed2,
    '--brand-primary-color-darker-10': '#cc031d',
    '--brand-primary-color-lighten-10': '#FCE6E9',
    '--brand-highlight-card-background': '#FFF8EA',
    '--brand-section-background': '#F9F6F6',
    '--brand-icon-grey-shade-1': 'unset',
    '--brand-secondary-icon-green': '#00C4B3',
    '--brand-search-bar-background': '#EFEFEF',
    '--brand-newsletter-footer-background': '#252525',
    '--brand-header-background': collectionWhite,
    '--brand-header-title': '#393939',
    '--brand-header-color-1': collectionPromoRed2,
    '--brand-header-color-text-1': collectionWhite,
    '--brand-header-color-text-2': '#393939',
    '--brand-header-color-tooltip-text': '#6E6963',
    '--brand-megamenu-background': '#f9f6f6',
    '--brand-megamenu-title': '#393939',
    '--brand-footer-background': '#EEECE8',
    '--brand-footer-title': '#393939',
    '--brand-footer-content': '#393939',
    '--brand-footer-icon': '#f4f0ed',
    '--brand-dark-blue': 'unset',
    '--brand-light-blue': 'unset',
    '--brand-dark-green': 'unset',
    '--brand-bright-green': 'unset',
    '--brand-light-green': 'unset',
    '--brand-yellow-shade-1': 'unset',
    '--brand-pink': 'unset',
    '--brand-yellow-shade-2': 'unset',
    '--brand-purple': '#7E2182',
    '--brand-font-regular': 'din',
    '--brand-font-bold': 'din-bold',
    '--brand-font-medium': 'din-medium',
  },
  dakFr: {
    '--brand-theme': 'dakFr',
    '--brand-primary-color-1': collectionPromoRed2,
    '--brand-primary-color-darker-10': '#cc031d',
    '--brand-primary-color-lighten-10': '#FCE6E9',
    '--brand-highlight-card-background': '#FFF8EA',
    '--brand-section-background': '#F9F6F6',
    '--brand-icon-grey-shade-1': 'unset',
    '--brand-secondary-icon-green': collectionColourTurqouise1,
    '--brand-search-bar-background': '#EFEFEF',
    '--brand-newsletter-footer-background': '#252525',
    '--brand-header-background': collectionWhite,
    '--brand-header-title': '#393939',
    '--brand-header-color-1': collectionPromoRed2,
    '--brand-header-color-text-1': collectionWhite,
    '--brand-header-color-text-2': '#393939',
    '--brand-header-color-tooltip-text': '#6E6963',
    '--brand-megamenu-background': '#f9f6f6',
    '--brand-megamenu-title': '#393939',
    '--brand-footer-background': '#F3F3F3',
    '--brand-footer-title': '#393939',
    '--brand-footer-content': '#393939',
    '--brand-footer-icon': '#f4f0ed',
    '--brand-dark-blue': 'unset',
    '--brand-light-blue': 'unset',
    '--brand-dark-green': 'unset',
    '--brand-bright-green': 'unset',
    '--brand-light-green': 'unset',
    '--brand-yellow-shade-1': 'unset',
    '--brand-pink': 'unset',
    '--brand-yellow-shade-2': 'unset',
    '--brand-purple': '#7E2182',
    '--brand-font-regular': 'din',
    '--brand-font-bold': 'din-bold',
    '--brand-font-medium': 'din-medium',
  },
  dakEs: {
    '--brand-theme': 'dakEs',
    '--brand-primary-color-1': collectionPromoRed2,
    '--brand-primary-color-darker-10': '#cc031d',
    '--brand-primary-color-lighten-10': '#FCE6E9',
    '--brand-highlight-card-background': '#FFF8EA',
    '--brand-section-background': collectionBlack,
    '--brand-icon-grey-shade-1': 'unset',
    '--brand-secondary-icon-green': collectionColourTurqouise1,
    '--brand-search-bar-background': collectionWhite,
    '--brand-newsletter-footer-background': '#F2F2F2',
    '--brand-header-background': '#000000',
    '--brand-header-title': collectionWhite,
    '--brand-header-color-1': collectionPromoRed2,
    '--brand-header-color-text-1': collectionWhite,
    '--brand-header-color-text-2': collectionWhite,
    '--brand-header-color-tooltip-text': '#6E6963',
    '--brand-megamenu-background': '#000000',
    '--brand-megamenu-title': collectionWhite,
    '--brand-footer-background': '#252525',
    '--brand-footer-title': '#ffffff',
    '--brand-footer-content': '#ffffff',
    '--brand-footer-icon': '#252525',
    '--brand-dark-blue': 'unset',
    '--brand-light-blue': 'unset',
    '--brand-dark-green': 'unset',
    '--brand-bright-green': 'unset',
    '--brand-light-green': 'unset',
    '--brand-yellow-shade-1': 'unset',
    '--brand-pink': 'unset',
    '--brand-yellow-shade-2': 'unset',
    '--brand-purple': '#7E2182',
    '--brand-font-regular': 'din',
    '--brand-font-bold': 'din-bold',
    '--brand-font-medium': 'din-medium',
  },
  emo: {
    '--brand-theme': 'emo',
    '--brand-primary-color-1': collectionPromoRed2,
    '--brand-primary-color-darker-10': '#cc031d',
    '--brand-primary-color-lighten-10': '#FCE6E9',
    '--brand-highlight-card-background': '#FFF8EA',
    '--brand-section-background': collectionPromoRed2,
    '--brand-icon-grey-shade-1': 'unset',
    '--brand-secondary-icon-green': collectionColourTurqouise1,
    '--brand-search-bar-background': collectionWhite,
    '--brand-newsletter-footer-background': '#525252',
    '--brand-header-background': '#E30421',
    '--brand-header-title': collectionWhite,
    '--brand-header-color-1': collectionWhite,
    '--brand-header-color-text-1': collectionPromoRed2,
    '--brand-header-color-text-2': collectionWhite,
    '--brand-header-color-tooltip-text': '#6E6963',
    '--brand-megamenu-background': '#E30421',
    '--brand-megamenu-title': collectionWhite,
    '--brand-footer-background': '#EFEFEF',
    '--brand-footer-title': '#6e6963',
    '--brand-footer-content': '#535353',
    '--brand-footer-icon': '#f4f0ed',
    '--brand-dark-blue': 'unset',
    '--brand-light-blue': 'unset',
    '--brand-dark-green': 'unset',
    '--brand-bright-green': 'unset',
    '--brand-light-green': 'unset',
    '--brand-yellow-shade-1': 'unset',
    '--brand-pink': 'unset',
    '--brand-yellow-shade-2': 'unset',
    '--brand-purple': '#7E2182',
    '--brand-font-regular': 'din',
    '--brand-font-bold': 'din-bold',
    '--brand-font-medium': 'din-medium',
  },
  lav: {
    '--brand-theme': 'lav',
    '--brand-primary-color-1': collectionPromoRed2,
    '--brand-primary-color-darker-10': '#cc031d',
    '--brand-primary-color-lighten-10': '#FCE6E9',
    '--brand-highlight-card-background': '#FFF8EA',
    '--brand-section-background': '#F9F6F6',
    '--brand-icon-grey-shade-1': 'unset',
    '--brand-secondary-icon-green': collectionColourTurqouise1,
    '--brand-search-bar-background': '#EFEFEF',
    '--brand-newsletter-footer-background': '#393939',
    '--brand-header-background': collectionWhite,
    '--brand-header-title': '#393939',
    '--brand-header-color-1': collectionPromoRed2,
    '--brand-header-color-text-1': collectionWhite,
    '--brand-header-color-text-2': '#393939',
    '--brand-header-color-tooltip-text': '#6E6963',
    '--brand-megamenu-background': collectionPromoRed2,
    '--brand-megamenu-title': collectionWhite,
    '--brand-footer-background': '#F3F3F3',
    '--brand-footer-title': '#393939',
    '--brand-footer-content': '#393939',
    '--brand-footer-icon': '#f4f0ed',
    '--brand-dark-blue': 'unset',
    '--brand-light-blue': 'unset',
    '--brand-dark-green': 'unset',
    '--brand-bright-green': 'unset',
    '--brand-light-green': 'unset',
    '--brand-yellow-shade-1': 'unset',
    '--brand-pink': 'unset',
    '--brand-yellow-shade-2': 'unset',
    '--brand-purple': '#7E2182',
    '--brand-font-regular': 'din',
    '--brand-font-bold': 'din-bold',
    '--brand-font-medium': 'din-medium',
  },
};

export const domainSearchBrandingMap = {
  [brands.dakotaSpain]: [/^https?:\/\/[^/]+\.dakotabox\.es/, /^https?:\/\/[^/]+\/ds\//],
  [brands.dakotaFrance]: [/^https?:\/\/[^/]+\.dakotabox\.fr/, /^https?:\/\/[^/]+\/df\//],
  [brands.emozione]: [/^https?:\/\/[^/]+\.emozione3\.it/, /^https?:\/\/[^/]+\/ez\//],
  [brands.lavidaesbella]: [/^https?:\/\/[^/]+\.lavidaesbella\.es/, /^https?:\/\/[^/]+\/lv\//],
  [brands.bongo]: [/^https?:\/\/[^/]+\.bongo\.nl/, /^https?:\/\/[^/]+\.bongo\.be/, /^https?:\/\/[^/]+\/(bb|bn)\//],
  [brands.cadeaubox]: [/^https?:\/\/[^/]+\.cadeaubox\.be/, /^https?:\/\/[^/]+\/cb\//],
  [brands.smartbox]: [/^https?:\/\/[^/]+\/(fr|es|it|de|dk|se|ch\/(it|fr))\//],
};

export const localeMap = {
  [locales.swissFrench]: [/\/ch\/fr\//],
  [locales.swissItalian]: [/\/ch\/it\//],
  [locales.swissGerman]: [/\/ch\//, /\/ch\/de\//],
  [locales.belgianFrench]: [/\.be\/fr\//, /\/be\/fr\//, /\/bb\/fr\//, /\/cb\/fr/],
  [locales.belgianDutch]: [/\.be\//, /\.be\/nl/, /\/be\/nl\//, /\/bb\//, /\/bb\/nl\//, /\/cb\//, /\/cb\/nl\//],
  [locales.french]: [/\.fr\//, /\/fr\//],
  [locales.italian]: [/\.it\//, /\/it\//, /\/ez\//],
  [locales.spanish]: [/\.es\//, /\/es\//, /\/ds\//, /\/lv\//],
  [locales.swedish]: [/\.se\//, /\/se\//],
  [locales.danish]: [/\.dk\//, /\/dk\//],
  [locales.dutch]: [/\.nl\//, /\/nl\//, /\/bn\//],
  [locales.irish]: [/\.ie\//, /\/ie\//],
  [locales.german]: [/\.de\//, /\/de\//],
};
