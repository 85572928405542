<template>
  <div>
    <header>
      <img alt="smartbox-logo" src="https://seekvectorlogo.com/wp-content/uploads/2018/04/smartbox-vector-logo.png" />
      <h1>PreCart Component Customization Tool PoC</h1>
    </header>
    <div>
      <label for="component-dropdown">Component selector: </label>
      <select name="component-dropdown" v-model="selectedComponent">
        <optgroup v-for="(group, index) in componentList" :key="index" :label="index">
          {{ index }}
          <option v-for="option in group" :key="option">
            {{ option }}
          </option>
        </optgroup>
      </select>
      <button @click="mountUnmountComponent">
        {{ isComponentMounted ? 'Unmount' : 'Mount' }}
      </button>
      <label for="component-dropdown">DevUrl: </label>

      <select name="component-dropdown" v-model="selectedDevUrl">
        <option v-for="name in devUrlList" :value="name" :key="name">
          {{ name }}
        </option>
      </select>

      <button @click="brandingRootDebug = !brandingRootDebug">
        {{ !brandingRootDebug ? 'Show Branding Debug' : 'Hide Branding Debug' }}
      </button>
      <div v-if="brandingRootDebug">
        <pre>{{ JSON.stringify(brandingRoot, null, 4) }}</pre>
      </div>

      <div v-if="isComponentMounted">
        <h4>Props</h4>
        <div id="precartProps">
          <div
            class="precart-PropTypes"
            v-for="(option, index) in {
              ...Object.keys(combinedExports[selectedComponent].propTypes),
            }"
            :key="index"
          >
            <label for="options-dropdown">{{ option }} </label>
            <textarea name="component-dropdown" :value="getCustomModelValue(option)" @input="setCustomModelValue(option, $event)" />
          </div>
        </div>
      </div>
    </div>
    <sbx-pcc v-if="isComponentMounted" :name="selectedComponent" :options="selectedOptions" />
    <div v-if="isComponentMounted">
      <h4>Generate PreCart Tag</h4>
      <button class="btn" @click="generateTag()">Generate Tag</button>
      <button class="btn" @click="generateShadowRootSEO()">Generate Tag with SEO</button>
      <button class="btn" @click="writeTag()">Clear Tag</button>
      <code id="generateTagDiv"></code>
      <div id="ssr">
        <div id="hiddenPCCCode"></div>
      </div>
    </div>
  </div>
</template>

<script>
import SbxPcc from '../SbxPCC_Wrapper.vue';
import componentExports from '@/components/componentExports';
import atomExports from '@/atoms/atomExports';
import { developmentUrls } from '@/SharedFe/utils/localization/developmentUrls';
import { brandingImporter } from '@/SharedFe/utils';

const combinedExports = {
  ...componentExports,
  ...atomExports,
};

export default {
  components: { SbxPcc },
  name: 'Index',
  data() {
    return {
      combinedExports,
      selectedComponent: '',
      selectedOptions: {},
      selectedDevUrl: sessionStorage.getItem('baseUrlOveride') || 'https://dev.smartbox.com/fr/',
      isComponentMounted: false,
      tagControlButtonLabel: 'Generate',
      initialUnmountedComponents: ['newsletterPopup, dummyBrochure'],
      brandingRootDebug: false,
      brandingRoot: brandingImporter(),
    };
  },
  mounted() {
    // In live sites, dataLayer array will be created by the adobe plugin as a global.
    // For PCC we should instantiate it here.
    window.dataLayer = [];
  },
  computed: {
    componentList() {
      return {
        componenst: Object.keys(componentExports),
        atoms: Object.keys(atomExports),
      };
    },
    devUrlList() {
      return developmentUrls;
    },
  },
  watch: {
    selectedDevUrl() {
      this.isComponentMounted = false;
      sessionStorage.setItem('baseUrlOveride', this.selectedDevUrl);
      this.brandingRoot = brandingImporter();
      setTimeout(() => {
        this.isComponentMounted = true;
      }, 300);
    },
    selectedComponent() {
      if (!this.selectedComponent || this.selectedComponent === '-') {
        this.selectedOptions = {};
      } else {
        const componentExport = combinedExports[this.selectedComponent];

        this.isComponentMounted = !this.initialUnmountedComponents.includes(this.selectedComponent);

        const options = {};
        for (const [key, value] of Object.entries(componentExport.propTypes)) {
          options[key] = componentExport.devProps[key] || value.default;
        }
        this.selectedOptions = options;
      }
    },
  },
  methods: {
    generateTag(ssr = '') {
      if (ssr) {
        // eslint-disable-next-line no-param-reassign
        ssr = `/* SEO GENERATED CONTENT BLOCK ${ssr} */ `;
      }
      const wrap = `
        <sbx-pcc
          name="${this.selectedComponent}"
          options='${JSON.stringify(this.selectedOptions, null, 2)}'>
          ${ssr} </sbx-pcc>`;
      this.writeTag(wrap);
      navigator.clipboard
        .writeText(wrap)
        .then(() => {
          alert('Snippit copied to clipboard. Paste into CMS');
        })
        .catch(err => {
          alert('Error in copying snippit: ', err);
        });
    },
    writeTag(wrap = '') {
      const tag = document.getElementById('generateTagDiv');
      let outerHTML = '';
      if (wrap !== '') {
        outerHTML = wrap;
        this.tagControlButtonLabel = 'Clear';
      }
      if (tag != null) {
        tag.innerText = outerHTML;
      }
    },
    mountUnmountComponent() {
      this.isComponentMounted = !this.isComponentMounted;
    },
    setCustomModelValue(option, event) {
      const { type } = combinedExports[this.selectedComponent].propTypes[option];
      if (type === Array || type === Object) {
        this.selectedOptions[option] = JSON.parse(event.target.value);
        return;
      }
      this.selectedOptions[option] = event.target.value;
    },
    getCustomModelValue(option) {
      const { type } = combinedExports[this.selectedComponent].propTypes[option];
      return type === Array || type === Object ? JSON.stringify(this.selectedOptions[option]) : this.selectedOptions[option];
    },
    generateShadowRootSEO() {
      const hiddenPCCCode = document.getElementById('hiddenPCCCode');
      const pcc = document.createElement('sbx-pcc');
      pcc.setAttribute('name', this.selectedComponent);
      pcc.setAttribute('options', `{}`);
      hiddenPCCCode.insertAdjacentElement('beforebegin', pcc);
      /*Timeout is to allow the code to generate a shadowDom
       in the browser and then retrieve its contents */
      setTimeout(() => {
        const ssr = document.getElementById('ssr').firstChild;
        const div = ssr.shadowRoot.querySelector('div').querySelector('*:not(.error)');
        const seoString = String(div.innerHTML);
        this.generateTag(seoString);
      }, 1000);
    },
  },
};
</script>
<style lang="scss">
header {
  text-align: center;

  img {
    max-width: 350px;
  }

  h1 {
    color: #7b6d69;
    text-decoration: underline;
  }
}

#precartProps {
  display: flex;
  flex-wrap: wrap;
  padding: 15px;
}

.precart-PropTypes {
  flex: 0 0 30%;
  padding: 0 15px;

  label {
    display: block;
  }
}

#ssr > sbx-pcc {
  display: none;
}
</style>
